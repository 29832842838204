<template>
  <section class="content px-4 py-2 my-2" name="MassiveOrders">
    <v-layout wrap>
      <v-flex xs12>
        <AppTitle title="Envios masivos" subtitle="---" />
      </v-flex>
      <v-flex xs12>
        <v-card>
          <AppCardTitle :title="`${orders.length} envios masivos`">
            <template slot="button">
              <v-btn :disabled="loading" depressed color="primary" class="ma-0" to="/configuracion/envio-masivo/nuevo">
                Crear envio masivo
              </v-btn>
            </template>
          </AppCardTitle>
          <v-divider></v-divider>
          <v-data-table
            v-model="selected"
            item-key="id"
            :headers="headers"
            :items="orders"
            :loading="loading"
            rows-per-page-text="Pedidos por pagina"
            no-data-text="No hay pedidos para generar boleta/factura"
            class="elevation-0"
            hide-actions
          >
            <template slot="items" slot-scope="props">
              <tr :active="props.selected">
                <td>
                  {{ props.item.id }}
                </td>
                <td>
                  {{ props.item.date_add }}
                </td>
                <td>
                  <Status :value="props.item.email"></Status>
                </td>
                <td>
                  <Status :value="props.item.phone"></Status>
                </td>
                <td align="right">
                  <v-btn
                    v-if="props.item.total.all > props.item.total.email && props.item.email"
                    :to="`/configuracion/envio-masivo/${props.item.id}`"
                    small
                    depressed
                    color="error"
                    class="ma-0"
                  >
                    {{ props.item.total.all - props.item.total.email }} email por enviar
                  </v-btn>
                  <v-btn
                    v-if="props.item.total.all > props.item.total.phone && props.item.phone"
                    :to="`/configuracion/envio-masivo/${props.item.id}`"
                    small
                    depressed
                    color="error"
                    class="ma-0 ml-2"
                  >
                    {{ props.item.total.all - props.item.total.phone }} wsp por enviar
                  </v-btn>
                  <v-btn
                    v-if="props.item.total.all === props.item.total.email && props.item.email"
                    :to="`/configuracion/envio-masivo/${props.item.id}`"
                    small
                    depressed
                    color="success"
                    class="ma-0"
                  >
                    {{ props.item.total.all }} Emails enviados
                  </v-btn>
                  <v-btn
                    v-if="props.item.total.all === props.item.total.phone && props.item.phone"
                    :to="`/configuracion/envio-masivo/${props.item.id}`"
                    small
                    depressed
                    color="success"
                    class="ma-0 ml-2"
                  >
                    Wsp enviados
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import { ENVIOS_MASIVOS, WSP_MSJ } from '../../../config'
import AppTitle from '../../useful/title.vue'
import AppCardTitle from '../../useful/titleCard.vue'
import AppTitleDialog from '../../useful/titleDialog.vue'
import Status from '../../useful/status.vue'

function getMonthName(monthNumber) {
  const date = new Date()
  date.setMonth(monthNumber - 1)

  return date.toLocaleString('es-Es', { month: 'long' })
}

export default {
  components: {
    AppTitle,
    AppCardTitle,
    Status
  },
  data() {
    const days = []
    for (let index = 1; index < 32; index++) {
      if (index < 10) {
        index = `0${index}`
      }
      days.push(String(index))
    }
    const months = []
    for (let index = 1; index < 13; index++) {
      if (index < 10) {
        index = `0${index}`
      }
      months.push({ id: index, name: getMonthName(index) })
    }
    return {
      wordsSelected: null,
      daySelected: null,
      monthSelected: null,
      dialogSend: false,
      days,
      months,
      minSend: 18000,
      typeDiscountSend: 0,
      discountSend: 15,
      expireSend: 14,
      subjectSend: null,
      emailSend: null,
      typeSend: [],
      modalDate: false,
      loading: false,
      dialogEmail: false,
      date: this.$moment()
        .add(7, 'days')
        .format('YYYY-MM-DD'),

      subject: null,
      wordsList: [],
      emailLists: [
        { id: 0, name: 'Por defecto', url: 'fecha_importante_otro' },
        { id: 1, name: 'Cumpleaños', url: 'fecha_importante_cumpleano' },
        { id: 2, name: 'Aniversarios', url: 'fecha_importante_aniversario' },
        { id: 3, name: 'Especial Navidad', url: 'fecha_importante_navidad' },
        { id: 4, name: '14 de Febrero', url: 'dia_de_los_enamorados' }
      ],
      itemsDiscountSend: [{ id: 0, name: 'Porcentaje' }, { id: 1, name: 'Monto' }],
      words: null,
      orders: [],
      ordersAll: [],
      selected: [],
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      headers: [
        {
          text: 'ID',
          align: 'left',
          sortable: false,
          value: 'order'
        },
        {
          text: 'Fecha de envio',
          align: 'left',
          sortable: false,
          value: 'date_add'
        },
        {
          text: 'Email',
          align: 'left',
          sortable: false,
          value: 'message'
        },
        {
          text: 'Wsp',
          align: 'left',
          sortable: false,
          value: 'message'
        },
        {
          text: '',
          align: 'left',
          sortable: false,
          value: 'order'
        }
      ]
    }
  },
  computed: {
    massive() {
      return this.$store.getters.getMassive
    },
    dateSelected() {
      const { daySelected, monthSelected } = this
      if (daySelected && monthSelected) return `2023-${monthSelected}-${daySelected}`
      return null
    }
  },
  watch: {
    dateSelected(e) {
      if (e) {
        this.getOrders()
      }
    },
    date() {
      this.getOrders()
    },
    wordsSelected(e) {
      if (e) {
        this.words = e.words
        this.filterOrders()
      }
    }
  },
  mounted() {
    this.getOrders()
  },
  methods: {
    async getOrders() {
      try {
        const res = await this.$http.get(`${ENVIOS_MASIVOS}`)
        this.orders = res.data
        this.ordersAll = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    }
  }
}
</script>
